export type HiveOnMinerGenericStats = {
    onlineWorkers: number;
    realTimeHashrate: number;
}

export async function getMinerStats (minerAddress: string): Promise<HiveOnMinerGenericStats> {
    return fetch(`https://hiveon.net/api/v1/stats/miner/${ minerAddress }/ETH`)
        .then(res => res.json())
        .then(data => {
            return {
                onlineWorkers: data.onlineWorkerCount,
                realTimeHashrate: data.hashrate
            }
        });
}
