import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import "../../../../styles/misc-animations.css";
import { useEffect, useState } from "react";
import { getMinerStats, HiveOnMinerGenericStats } from "../../../../modules/HiveonPoolAPI";


const faqs = [
    {
        question: "Selger dere noe?",
        answer: `Ja det gjør vi! Utstyr vi har brukt eller utstyr som vi ikke ønsker til eget bruk selger vi videre på Finn!\nVi selger for eksempel skjermkort og strømforsyninger.`
    },
    {
        question: "Er dere på utkikk etter investorer?",
        answer: "Ja det er vi! Vi ønsker helst å ekspandere driften vår og om du har noe å tilby er det bare å kontakte oss!"
    },
    {
        question: "Hva er fremtidsplanene deres?",
        answer: `En stund i fremtiden ser vi for oss å være mer kjent og ha ganske mye datakraft, i tillegg til å til å ha flere ansatte.`
    },
    {
        question: "Hva annet gjør dere enn å mine og selge ting?",
        answer: `Foreløpig er det ikke mye annet, men vi har i fremtiden planlagt å få tak i større lokaler slik at vi kan drive med større prosjekter (i.e. hosting av mining rigger)`
    }
    // More questions...
];

function classNames (...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export default function MainLandingPage () {
    const [ minerStats, setMinerStats ] = useState({
        onlineWorkers: 16,
        realTimeHashrate: 6168750000
    } as HiveOnMinerGenericStats);
    
    
    useEffect(() => {
        (async () => {
            const updatedMinerStats = await getMinerStats("d77b88ed715172fff70dc1a6d9fc2a27ff32caf8");
            
            if (updatedMinerStats && updatedMinerStats.onlineWorkers && updatedMinerStats.realTimeHashrate) {
                setMinerStats(updatedMinerStats);
            }
        })();
    }, []);
    
    return (
        <>
            <main className="w-full mr-0 ml-0">
                <div>
                    {/* Hero card */ }
                    <div className="relative">
                        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"/>
                        <div className="w-full">
                            <div className="relative shadow-xl sm:overflow-hidden">
                                <div className="absolute inset-0">
                                    <img
                                        className="h-full w-full object-cover"
                                        src="/assets/img/misc/rig_example_1.jpg"
                                        alt="GPU Mining rigs"
                                    />
                                    <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply"/>
                                </div>
                                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 mt-10 mb-10">
                                    <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl mt-10 pt-10">
                                        <span className="block text-white">Vi gjør krypto tryggere.</span>
                                        <span className="block text-green-400">Sammen.</span>
                                    </h1>
                                    <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                                        Vi driver med "mining" av kryptovaluta, og sørger for stabilitet og sikkerhet
                                        innenfor kryptomarkedet. Vi selger også utstyr, og er gjerne på utkikk etter
                                        investorer
                                        for å utvide driften vår.
                                    </p>
                                    <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                        <div
                                            className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                                            <a
                                                href="#"
                                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                                            >
                                                Kontakt Oss
                                            </a>
                                            <a
                                                href="#"
                                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                                            >
                                                Vår butikk
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Logo cloud */ }
                    <div className="bg-gray-800">
                        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                                Som lest om i
                            </p>
                            <div className="mt-6 flex justify-center">
                                <div className="content-center">
                                    <a
                                        href="https://firda.no/"
                                    >
                                        <img
                                            className="h-12"
                                            src="https://r.api.no/local/v3/publications/www.firda.no/gfx/small-positive.svg"
                                            alt="Tuple"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Generelle opplysninger */ }
                <div className="h-4 w-full gradient-div">
                </div>
                
                {/* Hva er mining?*/ }
                <div className="mt-24 container mx-auto">
                    <div
                        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div
                            className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Hva er egentlig mining?
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Mining handler om å sikre krypto-nettverket og verifisere transaksjoner
                                        før de blir overført. For å verifisere transaksjonene må man løse komplekse
                                        matematiske oppgaver som blir gjort med dedikert maskinvare. Man får betalt
                                        i valutaen man "miner" i.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="w-full mx-auto lg:absolute lg:right-0 lg:h-full lg:w-auto max-w-none"
                                    src="/assets/img/icons/undraw_nakamoto.svg"
                                    alt="Customer profile user interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10 mb-10 container mx-auto">
                    <div className="h-0.5 gradient-div"/>
                </div>
                
                {/* Hvordan tjener man penger på dette? */ }
                <div className="mt-24 container mx-auto">
                    <div
                        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div
                            className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Hvordan tjener man penger på dette?
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Fordi man sikrer nettverket blir man kompensert med valutaen man sikrer, og det
                                        blir delt ut såkallet "pool rewards" som er en belønning til alle i en enkelt
                                        gruppe
                                        som var med på å verifisere transaksjonene av et visst volum.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="w-full mx-auto lg:absolute lg:right-0 lg:h-full lg:w-auto max-w-none"
                                    src="/assets/img/icons/undraw_bitcoin_p2p.svg"
                                    alt="Customer profile user interface"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className="bg-indigo-800">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
                        <div className="max-w-4xl mx-auto text-center">
                            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">Hvor langt har vi
                                kommet?</h2>
                            <p className="mt-3 text-xl text-indigo-200 sm:mt-4">
                                Under kan du se hvordan alt kjører nå
                            </p>
                        </div>
                        <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-2 sm:gap-8">
                            <div className="flex flex-col mt-10 sm:mt-0">
                                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">Rigger</dt>
                                <dd className="order-1 text-5xl font-extrabold text-white">
                                    {
                                        minerStats.onlineWorkers
                                    }
                                </dd>
                            </div>
                            <div className="flex flex-col">
                                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">Hashrate
                                    ETH
                                </dt>
                                <dd className="order-1 text-5xl font-extrabold text-white">
                                    {
                                        (minerStats.realTimeHashrate / 1000000000).toString().slice(0, 5)
                                    }
                                    + GH/s
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
    
    
                <div className="h-4 w-full gradient-div">
                </div>
                
                {/* FAQ */ }
                <div className="bg-gray-50">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                            <h2
                                className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl"
                            >
                                Hva vi ofte blir spurt om (FAQ)
                            </h2>
                            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                                { faqs.map((faq) => (
                                    <Disclosure as="div" key={ faq.question } className="pt-6">
                                        { ({ open }) => (
                                            <>
                                                <dt className="text-lg">
                                                    <Disclosure.Button
                                                        className="text-left w-full flex justify-between items-start text-gray-400">
                                                        <span
                                                            className="font-medium text-gray-900">{ faq.question }</span>
                                                        <span className="ml-6 h-7 flex items-center">
                                                          <ChevronDownIcon
                                                              className={ classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform") }
                                                              aria-hidden="true"
                                                          />
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                    <p className="text-base text-gray-500">{ faq.answer }</p>
                                                </Disclosure.Panel>
                                            </>
                                        ) }
                                    </Disclosure>
                                )) }
                            </dl>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
