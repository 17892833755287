import { RouteConfig } from "react-router-config";
import LandingSection from "./landing";


const appRoutes: RouteConfig[] = [
    {
        path: "/",
        exact: false,
        component: LandingSection
    }
];


export default appRoutes;
