import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { ChartBarIcon, MailIcon, ShoppingCartIcon } from "@heroicons/react/solid";


const navigation = [
    {
        name: "Kontakt oss",
        href: "/kontakt-oss",
        icon: <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    },
    {
        name: "Vår butikk",
        href: "https://www.finn.no/butikk/skminingas?sort=RELEVANCE",
        icon: <ShoppingCartIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    },
    {
        name: "Investorer",
        href: "/investorer",
        icon: <ChartBarIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    }
];

function classNames (...classes: any) {
    return classes.filter(Boolean).join(" ");
}


type Props = {
    isLoading: boolean;
}

export default function LandingNavbar (props: Props) {
    return (
        <>
            <Popover className="fixed top-0 w-full pt-5 pb-5 z-50 z-30 bg-white shadow-lg">
                { ({ open }) => (
                    <>
                        <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true"/>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6">
                            <nav
                                className="relative flex items-center justify-between sm:h-10 md:justify-center"
                                aria-label="Global"
                            >
                                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <a href="/">
                                            <span className="sr-only">Workflow</span>
                                            <img
                                                className="h-8 w-auto sm:h-10"
                                                src="/assets/img/logos/sk-mining-default-logo.svg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="-mr-2 flex items-center md:hidden">
                                            <Popover.Button
                                                className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Open main menu</span>
                                                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:flex md:space-x-10">
                                    { navigation.map((item) => (
                                        <a key={ item.name } href={ item.href }
                                           className="font-medium text-gray-500 hover:text-gray-900 py-2 px-4 shadow-sm rounded hover:bg-gray-200 transition inline-flex items-center">
                                            { item.icon }
                                            { item.name }
                                        </a>
                                    )) }
                                </div>
                            </nav>
                        </div>
                        
                        <Transition
                            show={ open }
                            as={ Fragment }
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                static
                                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30"
                            >
                                <div
                                    className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-8 w-auto"
                                                src="/assets/img/logos/sk-mining-default-logo.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button
                                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close main menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3 space-y-1 flex align">
                                        { navigation.map((item) => (
                                            <a
                                                key={ item.name }
                                                href={ item.href }
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                            >
                                                { item.icon }
                                                { item.name }
                                            </a>
                                        )) }
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                ) }
            </Popover>
        </>
    );
};
