import React from "react";
import { renderRoutes } from "react-router-config";
import landingSectionRoutes from "./routes";
import LandingNavbar from "./components/navbar/LandingNavbar";
import LandingFooter from "./components/footer/LandingFooter";


export default function LandingSection () {
    return (
        <>
            <LandingNavbar isLoading={false}/>
            {
                renderRoutes(landingSectionRoutes)
            }
            <LandingFooter/>
        </>
    );
};
