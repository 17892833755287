import React, { Suspense } from "react";
import "./global.css";
import { Provider as StoreProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import appRoutes from "../views/routes";
import defaultStore from "./store/configureStore";


export default function App (props: any) {
    
    return (
        <StoreProvider store={ defaultStore }>
            <CookiesProvider>
                <BrowserRouter>
                    <Suspense fallback={ <></> }>
                        {
                            renderRoutes(appRoutes)
                        }
                    </Suspense>
                </BrowserRouter>
            </CookiesProvider>
        </StoreProvider>
    );
};
