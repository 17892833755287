import { RouteConfig } from "react-router-config";
import { lazy } from "react";
import MainLandingPage from "./views/main";


const appRoutes: RouteConfig[] = [
    {
        path: "/",
        exact: true,
        component: MainLandingPage
    },
    {
        path: "/investorer",
        exact: true,
        component: lazy(() => import("./views/investorer"))
    },
    {
        path: "/kontakt-oss",
        exact: true,
        component: lazy(() => import("./views/kontakt-oss"))
    }
];


export default appRoutes;
