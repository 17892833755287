import { ChartBarIcon, MailIcon, ShoppingCartIcon } from "@heroicons/react/solid";
import "../../../../styles/misc-animations.css";


const navigation = [
    {
        name: "Kontakt oss",
        href: "/kontakt-oss",
        icon: <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    },
    {
        name: "Vår butikk",
        href: "https://www.finn.no/butikk/skminingas?sort=RELEVANCE",
        icon: <ShoppingCartIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    },
    {
        name: "Investorer",
        href: "/investorer",
        icon: <ChartBarIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
    }
];

export default function LandingFooter () {
    return (
        <>
            <footer className="bg-white">
                <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <div className="flex justify-center">
                        <img
                            width={ 256 }
                            src="/assets/img/logos/sk-mining-default-logo.svg"
                        />
                    </div>
                    
                    <div className="h-0.5 gradient-div mt-2 pb-5 z-10 w-10 mx-auto"/>
                    
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        { navigation.map((item) => (
                            <div key={ item.name } className="px-5 py-2">
                                <a href={ item.href } className="text-base text-gray-500 hover:text-gray-900">
                                    { item.name }
                                </a>
                            </div>
                        )) }
                    </nav>
                    <p
                        className="mt-8 text-center text-base text-gray-400"
                    >
                        &copy; 2021 SK Mining AS. Org.nr: 927160536.
    
                        <br/>
                        Alle rettigheter forbeholdt.
                    </p>
                </div>
            </footer>
        </>
    );
};
